import { useState } from 'react';
import useTagsSearch from '~/hooks/use-tags-search';
import useTiering from './use-tiering';

function useSearchSimsFilters(organizationId, initialFilter = null) {
  const tagsSearch = useTagsSearch('tags', organizationId, true);
  const connectorsSearch = useTagsSearch('connectors', organizationId, true);
  const networkListsSearch = useTagsSearch('network-lists', organizationId, true);
  const tiering = useTiering();
  const [activeFilters, setActiveFilters] = useState(initialFilter ?? []);
  function clear() {
    if (activeFilters.length <= 0) return;
    setActiveFilters([]);
  }

  function removeValue(key, value) {
    setActiveFilters(activeFilters.filter(filter => filter.key !== key || filter.value !== value));
  }

  function setValue(key, value) {
    const newFilter = { key, value };

    function checkIfKeyIsPresent(filter) {
      // The status keys are mutually exclusive, i.e. there can only be one at a time, which is why we need this extra check
      const statusRelatedKeys = ['activated', 'online'];
      const isStatusRelatedKey = statusRelatedKeys.includes(key);
      return !isStatusRelatedKey ? filter.key === key : statusRelatedKeys.includes(filter.key);
    }

    const uniqueFilters = [...activeFilters.filter(currentFilter => !checkIfKeyIsPresent(currentFilter)), newFilter];
    setActiveFilters(uniqueFilters);
  }

  function getValue(key, items) {
    if (!items.length) return undefined;
    const currentFilter = activeFilters.find(filter => filter.key === key);
    if (!currentFilter) return undefined;
    const filterItem = items.find(item => item.value === currentFilter.value || item.label === currentFilter.value);
    if (!filterItem) return undefined;
    return filterItem.value;
  }

  function getSimType() {
    const currentFilter = activeFilters.find(filter => filter.key === 'isSoftSim');
    if (!currentFilter) return undefined;
    return 'isSoftSim:' + currentFilter.value;
  }

  function getStatus() {
    const currentStatusActivated = activeFilters.find(filter => filter.key === 'activated');
    const currentStatusOnline = activeFilters.find(filter => filter.key === 'online');
    if (!currentStatusActivated && !currentStatusOnline) return undefined;
    if (currentStatusActivated) return 'activated:' + currentStatusActivated.value;
    if (currentStatusOnline) return 'online:' + currentStatusOnline.value;
  }

  const connectorSelector = {
    placeholder: 'Connector',
    key: 'connector',
    type: 'combobox',
    selected: getValue('connector', connectorsSearch.items),
    onSelectItem: value => {
      const item = connectorsSearch.items.find(item => item.value === value);
      setValue('connector', item.label);
    },
    onDeselectItem: value => {
      const item = connectorsSearch.items.find(item => item.value === value);
      removeValue('connector', item?.label ?? value);
    },
    width: '136px',
    items: connectorsSearch.items,
    loading: connectorsSearch.loading,
    search: connectorsSearch.search,
    setSearch: connectorsSearch.setSearch,
    startPaging: connectorsSearch.startPaging,
    canSearch: connectorsSearch.canSearch,
  };

  const filters = {
    activeFilters,
    clear,
    setValue,
    filters: [
      {
        placeholder: 'Tags',
        key: 'tag',
        type: 'combobox',
        selected: getValue('tag', tagsSearch.items),
        onSelectItem: value => {
          const item = tagsSearch.items.find(item => item.value === value);
          setValue('tag', item.label);
        },
        onDeselectItem: value => {
          const item = tagsSearch.items.find(item => item.value === value);
          removeValue('tag', item?.label ?? value);
        },
        width: '136px',
        items: tagsSearch.items,
        loading: tagsSearch.loading,
        search: tagsSearch.search,
        setSearch: tagsSearch.setSearch,
        startPaging: tagsSearch.startPaging,
        canSearch: tagsSearch.canSearch,
      },
      {
        placeholder: 'Network list',
        key: 'networkWhitelist',
        type: 'combobox',
        selected: getValue('networkWhitelist', networkListsSearch.items),
        onSelectItem: value => {
          const item = networkListsSearch.items.find(item => item.value === value);
          setValue('networkWhitelist', item.label);
        },
        onDeselectItem: value => {
          const item = networkListsSearch.items.find(item => item.value === value);
          removeValue('networkWhitelist', item?.label ?? value);
        },
        width: '136px',
        items: networkListsSearch.items,
        loading: networkListsSearch.loading,
        search: networkListsSearch.search,
        setSearch: networkListsSearch.setSearch,
        startPaging: networkListsSearch.startPaging,
        canSearch: networkListsSearch.canSearch,
      },
      {
        placeholder: 'Status',
        key: 'status',
        type: 'combobox',
        selected: getStatus(),
        onSelectItem: value => {
          const [itemKey, itemValue] = value.split(':');
          setValue(itemKey, itemValue);
        },
        onDeselectItem: value => {
          const [itemKey, itemValue] = value.split(':');
          removeValue(itemKey, itemValue);
        },
        width: '96px',
        items: [
          { label: 'Online', value: 'online:true' },
          { label: 'Offline', value: 'online:false' },
          { label: 'Active', value: 'activated:true' },
          { label: 'Deactivated', value: 'activated:false' },
        ],
        haveSearch: false,
      },
      {
        placeholder: 'Type',
        key: 'isSoftSim',
        type: 'combobox',
        selected: getSimType(),
        onSelectItem: value => {
          const [itemKey, itemValue] = value.split(':');
          setValue(itemKey, itemValue);
        },
        onDeselectItem: value => {
          const [itemKey, itemValue] = value.split(':');
          removeValue(itemKey, itemValue);
        },
        width: '96px',
        items: [
          { label: 'SoftSim', value: 'isSoftSim:true' },
          { label: 'Physical', value: 'isSoftSim:false' },
        ],
        haveSearch: false,
      },
    ],
  };

  if (tiering.hasFeature('connectors')) filters.filters.splice(2, 0, connectorSelector);

  return filters;
}

export default useSearchSimsFilters;
