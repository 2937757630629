/* eslint-disable react-hooks/rules-of-hooks */
// https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-with-react-hook-ba77c37c7e82
import { useEffect } from 'react';

function onClickOutside(nodes, onOutsideClick) {
  function handleClick(e) {
    const targetNodes = Array.isArray(nodes) ? nodes : [nodes];
    if (targetNodes.some(n => !n?.current || n.current.contains(e.target))) return;
    onOutsideClick(e);
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
}

export default onClickOutside;
