import React from 'react';
import PropTypes from 'prop-types';
import InputWrapper, { propTypes as InputWrapperPropTypes } from './Elements/InputWrapper';
import BytesInput from './Elements/BytesInput';
import FancyUnitInput from './Elements/FancyUnitInput';
import TextArea from './Elements/TextArea';
import Checkbox from './Elements/Checkbox';
import Select from './Elements/Select';
import Color from './Elements/Color';
import MonthPicker from '~/components/MonthPicker';
import DragAndDropUploadFile from './Elements/DragAndDropUploadFile';
import useTiering from '~/hooks/use-tiering';
import styled from 'styled-components';
import { capitalize } from '~/lib/strings';
import { HelpText, HelpTextLink } from '~/components/Text';
import TextInputWrapper from './Elements/TextInput/TextInputWrapper';
import TextInput from './Elements/TextInput';
import HiddenInput from './Elements/HiddenInput';
import { RoundedInput, RoundedNameInput } from './Elements/RoundedInput';
import PhoneInputEl from './Elements/PhoneInput';

const Input = React.forwardRef(function Input(
  { label, type, inverse = false, inline = false, noMargin = false, helpText, renderFunc, className, error, ...rest },
  ref,
) {
  let el;
  let align = 'flex-start';
  // TODO: hack
  let addTopMargin = false;
  if (type === 'textarea') {
    el = (
      <TextArea
        as='textarea'
        error={error}
        ref={ref}
        {...rest}
      />
    );
    align = 'baseline';
  } else if (type === 'checkbox') {
    el = (
      <Checkbox
        ref={ref}
        type='checkbox'
        {...rest}
      />
    );
    align = 'center';
    addTopMargin = true;
  } else if (type === 'select') {
    el = (
      <Select
        mode='single'
        ref={ref}
        inline={inline}
        error={error}
        {...rest}
      />
    );
  } else if (type === 'bytes') {
    el = <BytesInput {...rest} />;
  } else if (type === 'fancy-unit-input') {
    el = (
      <FancyUnitInput
        error={error}
        {...rest}
      />
    );
  } else if (type === 'multi-select') {
    el = (
      <Select
        mode='multi'
        error={error}
        {...rest}
      />
    );
  } else if (type === 'color') {
    el = <Color {...rest} />;
    addTopMargin = true;
  } else if (type === 'drag-and-drop-file-upload') {
    el = <DragAndDropUploadFile {...rest} />;
  } else if (type === 'month-picker') {
    el = <MonthPicker {...rest} />;
  } else if (type === 'search') {
    el = (
      <TextInputWrapper
        ref={ref}
        type={type}
        error={error}
        {...rest}
      />
    );
  } else if (type === 'hidden-input') {
    el = (
      <HiddenInput
        ref={ref}
        type={type}
        error={error}
        {...rest}
      />
    );
  } else if (type === 'rounded-input') {
    el = (
      <RoundedInput
        ref={ref}
        type={type}
        error={error}
        {...rest}
      />
    );
  } else if (type === 'rounded-name-input') {
    el = (
      <RoundedNameInput
        ref={ref}
        type={type}
        error={error}
        {...rest}
      />
    );
  } else if (type === 'rounded-password') {
    el = (
      <RoundedInput
        ref={ref}
        type='password'
        error={error}
        {...rest}
      />
    );
  } else if (type === 'phone-number') {
    el = <PhoneInputEl {...rest} />;
  } else {
    el = (
      <TextInput
        ref={ref}
        type={type}
        error={error}
        {...rest}
      />
    );
  }

  return (
    <InputWrapper
      label={label}
      align={align}
      inline={inline}
      addTopMargin={addTopMargin}
      noMargin={noMargin}
      inverse={inverse}
      helpText={helpText}
      className={className}
      error={error}
      type={type}
      {...rest}
    >
      {/* TODO: renderFunc is a hack until model is rewritten */}
      {renderFunc ? renderFunc(rest.value, el) : el}
    </InputWrapper>
  );
});

const HelpTextWrapper = styled.div`
  display: flex;
`;

const PreRender = React.forwardRef(function PreRender(
  { featureName, featureLink, featureShowCTA = true, ...props },
  ref,
) {
  try {
    const tiering = useTiering();

    // eslint-disable-next-line no-inner-declarations
    function MissingFeatureLabel() {
      if (!featureShowCTA) return null;
      const tierRequired = tiering.getTierByFeature(featureName);
      if (!tierRequired) return <HelpText>Upgrade to access feature</HelpText>;
      return (
        <>
          <HelpText>Feature available on </HelpText>
          <HelpTextLink> {capitalize(tierRequired)} users</HelpTextLink>
        </>
      );
    }

    // eslint-disable-next-line no-inner-declarations
    function setDisabledAndPlaceholder() {
      const hasFeature = tiering.hasFeature(featureName);
      if (!featureName || hasFeature) return {};

      return {
        disabled: true,
        placeholder: '',
        canSearch: false,
        // eslint-disable-next-line react/jsx-no-target-blank
        helpText: (
          <a
            href={featureLink}
            target='_blank'
          >
            <HelpTextWrapper>
              <MissingFeatureLabel />
            </HelpTextWrapper>
          </a>
        ),
      };
    }

    const changes = setDisabledAndPlaceholder();

    return (
      <Input
        {...props}
        {...changes}
        ref={ref}
      />
    );
  } catch (e) {
    const isFeatureProviderError = e.message === 'useTiering must be used within a FeatureProvider';
    if (!isFeatureProviderError) throw e;
    return (
      <Input
        {...props}
        ref={ref}
      />
    );
  }
});

const inputPropTypes = {
  ...InputWrapperPropTypes,
  renderFunc: PropTypes.func,
  featureLink: PropTypes.string,
  featureShowCTA: PropTypes.bool,
};

PreRender.propTypes = inputPropTypes;
Input.propTypes = inputPropTypes;

export default PreRender;
