import React from 'react';
import Modali from 'modali';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '~/components/Button';
import { Theme } from '@radix-ui/themes';

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

function ModalForm({
  modalOpts,
  onClickCancel,
  largeButtons = false,
  cancelText = 'Cancel',
  onClickSubmit,
  submitText = 'Save',
  children,
  submitDisabled = false,
}) {
  function onSubmit(e) {
    e.preventDefault();
  }

  return (
    <Modali.Modal {...modalOpts}>
      <Theme>
        <Form onSubmit={onSubmit}>
          <div className='modali-body-style'>{children}</div>
          <div className='modali-footer'>
            <ButtonWrapper>
              {onClickCancel && (
                <Button
                  type='button'
                  variant='secondary'
                  onClick={onClickCancel}
                  size={largeButtons && 'large'}
                >
                  {cancelText}
                </Button>
              )}
              {onClickSubmit && (
                <Button
                  variant='primary'
                  onClick={onClickSubmit}
                  disabled={submitDisabled}
                  size={largeButtons && 'large'}
                >
                  {submitText}
                </Button>
              )}
            </ButtonWrapper>
          </div>
        </Form>
      </Theme>
    </Modali.Modal>
  );
}

ModalForm.propTypes = {
  modalOpts: PropTypes.object.isRequired,
  largeButtons: PropTypes.bool,
  onClickCancel: PropTypes.func,
  onClickSubmit: PropTypes.func,
  onHide: PropTypes.func,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  submitDisabled: PropTypes.bool,
};

export default ModalForm;
