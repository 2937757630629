import React from 'react';
import SiteContainer from '~/components/SiteContainer';
import Menu from '../Menu';
import NetworkService from '~/containers/NetworkService';

function NetworkServicePage() {
  return (
    <SiteContainer>
      <Menu />
      <NetworkService />
    </SiteContainer>
  );
}

export default NetworkServicePage;
