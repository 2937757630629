import PropTypes from 'prop-types';
import { Container } from '@radix-ui/themes';

function SiteContainer({ children }) {
  return (
    <Container
      pt='6'
      pb='9'
    >
      {children}
    </Container>
  );
}

SiteContainer.propTypes = {
  children: PropTypes.node,
};

export default SiteContainer;
