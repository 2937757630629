import * as colors from '@radix-ui/colors';

export const ACCENT_COLOR = 'orange';
export const ERROR_COLOR = 'red';
export const INFO_COLOR = 'blue';
export const SUCCESS_COLOR = 'green';
export const WARNING_COLOR = 'yellow';
export const GRAY_COLOR = 'slate';

function removeLettersFromObjectKeys(obj) {
  const newObj = {};
  for (const key in obj) {
    const match = /\d+/.exec(key); // Find the number in the key
    if (match) {
      newObj[match[0]] = obj[key]; // Use the number as the new key
    }
  }
  return newObj;
}

function setSemanticAndNeutralColors(theme) {
  theme.color.accent = theme.color[ACCENT_COLOR];
  theme.color.accentAlpha = theme.color[`${ACCENT_COLOR}Alpha`];
  theme.color.error = theme.color[ERROR_COLOR];
  theme.color.errorAlpha = theme.color[`${ERROR_COLOR}Alpha`];
  theme.color.info = theme.color[INFO_COLOR];
  theme.color.infoAlpha = theme.color[`${INFO_COLOR}Alpha`];
  theme.color.success = theme.color[SUCCESS_COLOR];
  theme.color.successAlpha = theme.color[`${SUCCESS_COLOR}Alpha`];
  theme.color.warning = theme.color[WARNING_COLOR];
  theme.color.warningAlpha = theme.color[`${WARNING_COLOR}Alpha`];
  theme.color.neutral = theme.color[GRAY_COLOR];
  theme.color.neutralAlpha = theme.color[`${GRAY_COLOR}Alpha`];
  return theme;
}

const light = {
  color: {
    red: colors.red,
    redAlpha: colors.redA,
    blue: colors.blue,
    blueAlpha: colors.blueA,
    green: colors.green,
    greenAlpha: colors.greenA,
    yellow: colors.yellow,
    yellowAlpha: colors.yellowA,
    orange: colors.orange,
    orangeAlpha: colors.orangeA,
    slate: colors.slate,
    slateAlpha: colors.slateA,
  },
};

const dark = {
  color: {
    red: colors.redDark,
    redAlpha: colors.redDarkA,
    blue: colors.blueDark,
    blueAlpha: colors.blueDarkA,
    green: colors.greenDark,
    greenAlpha: colors.greenDarkA,
    yellow: colors.yellowDark,
    yellowAlpha: colors.yellowDarkA,
    orange: colors.orangeDark,
    orangeAlpha: colors.orangeDarkA,
    slate: colors.slateDark,
    slateAlpha: colors.slateDarkA,
  },
};

function generateColorTheme(theme) {
  Object.keys(theme.color).forEach(color => {
    theme.color[color] = removeLettersFromObjectKeys(theme.color[color]);
  });
  return setSemanticAndNeutralColors(theme);
}

export default {
  light: generateColorTheme(light),
  dark: generateColorTheme(dark),
};
