import { Button, ChevronDownIcon, Flex, Popover, Text } from '@radix-ui/themes';
import styled, { css } from 'styled-components';
import Icon from '../Icon';

export const StyledButton = styled(Button)`
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--gray-a7);

  &:where([data-disabled]) {
    background-color: var(--gray-a2);
    box-shadow: inset 0 0 0 1px var(--gray-a6);

    ${props =>
      props.isValueSet &&
      css`
        background-color: var(--gray-a3);
      `}
  }

  &:not([data-disabled]) {
    &:hover,
    &:active {
      box-shadow: inset 0 0 0 1px var(--gray-a8);
    }

    &:active {
      background-color: var(--gray-a3);
    }
  }
`;

export const StyledIcon = styled(Icon)`
  & svg {
    & path {
      fill: ${props => `var(--${props.color}-a11)`};
    }
  }
`;

export const StyledIconWithPointer = styled(StyledIcon)`
  cursor: pointer;
`;

export const Item = styled(Flex)`
  background-color: var(--gray-surface);
  border-radius: ${props => props.theme.radius[2]};
  cursor: pointer;

  :is(&:hover, &[data-checked='true'], &[data-highlighted='true'])
    + :is(&:hover, &[data-checked='true'], &[data-highlighted='true']) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  :is(&:hover, &[data-checked='true'], &[data-highlighted='true'])
    :has(+ :is(&:hover, &[data-checked='true'], &[data-highlighted='true'])) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &[data-checked='true'] {
    background-color: ${props => `var(--${props.color}-a4)`};
  }

  &:hover,
  &:active,
  &[data-highlighted='true'] {
    background-color: var(--gray-a3);
  }
`;

const StyledTextBlack = styled(Text)`
  color: var(--gray-12);
`;

export const StyledTextSingle = styled(StyledTextBlack)`
  max-width: 100%;
`;

export const StyledTextMultiple = styled(StyledTextBlack)`
  max-width: 80px;
`;

export const StyledPlaceholder = StyledTextSingle;

export const StyledPopoverContent = styled(Popover.Content)`
  z-index: ${props => props.theme.zIndex.modal + 1};
  padding: var(--space-2);
`;

export const StyledChevronDownIcon = styled(ChevronDownIcon)`
  flex: none;
`;
