import { Text } from '@radix-ui/themes';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// ------------------------------
// Typography v4
// 2025 -> 🤷
// ------------------------------
// Text
// https://www.figma.com/design/edp8Jm4u5954IDSQJ5utgn/ODS---Components?node-id=800-3750&t=opM2HoWbMwA5M7xI-4

export const InputErrorTextCss = css`
  color: var(--red-a10);
`;

export const InputErrorText = styled(Text)`
  ${InputErrorTextCss}
`;

export const InputHelpText = styled(Text)`
  color: var(--gray-a9);
`;

// ------------------------------
// Typography v3
// 2024 -> 2025
// ------------------------------
// Text
// https://www.figma.com/design/edp8Jm4u5954IDSQJ5utgn/ODS---Components?node-id=800-3750&t=opM2HoWbMwA5M7xI-4

const textSize1Css = css`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.48px;
`;

const textSize2Css = css`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.42px;
`;

const textSize3Css = css`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
`;

const textSize4Css = css`
  font-size: 18px;
  line-height: 26px;
`;

const textSize5Css = css`
  font-size: 20px;
  line-height: 28px;
`;

const textSize6Css = css`
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.1px;
`;

const textSize7Css = css`
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.12px;
`;

const textSize8Css = css`
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.16px;
`;

const textSize9Css = css`
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.4px;
`;

const textSizesCss = css`
  ${textSize2Css} /* default */
  ${props => props.size === 1 && textSize1Css}
  ${props => props.size === 3 && textSize3Css}
  ${props => props.size === 4 && textSize4Css}
  ${props => props.size === 5 && textSize5Css}
  ${props => props.size === 6 && textSize6Css}
  ${props => props.size === 7 && textSize7Css}
  ${props => props.size === 8 && textSize8Css}
  ${props => props.size === 9 && textSize9Css}
`;

const textWeightRegularCss = css`
  font-weight: 400;
`;

const textWeightMediumCss = css`
  font-weight: 500;
`;

const textWeightBoldCss = css`
  font-weight: 700;
`;

const textWeightCss = css`
  ${textWeightRegularCss} /* default */
  ${props => props.weight === 'medium' && textWeightMediumCss}
  ${props => props.weight === 'bold' && textWeightBoldCss}
`;

export const LegacyText = styled.p`
  ${textSizesCss}
  ${textWeightCss}
  text-align: ${props => props.align || 'start'};

  color: ${props =>
    props.color
      ? props.color
      : props.highContrast
      ? props.theme.color.neutralAlpha[11]
      : props.theme.color.neutral[12]};
`;

LegacyText.propTypes = {
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9]),
  weight: PropTypes.oneOf(['regular', 'medium', 'bold']),
  color: PropTypes.string,
  highContrast: PropTypes.bool,
  align: PropTypes.oneOf(['start', 'center', 'end', 'justify']),
};

// ------------------------------
// Typography v2
// 2024 -> 🤷
// ------------------------------
const weightRegularCss = css`
  font-weight: 400;
`;

const weightMediumCss = css`
  font-weight: 500;
`;

const weightCss = css`
  ${weightRegularCss}
  ${props => props.weight === 'medium' && weightMediumCss}
`;

const displayLargeCss = css`
  font-size: 48px; /* 3rem */
  line-height: 60px; /* 3.75rem */
`;

const displayMediumCss = css`
  font-size: 36px; /* 2.25rem */
  line-height: 44px; /* 2.75rem */
`;

const displaySmallCss = css`
  font-size: 32px; /* 2rem */
  line-height: 40px; /* 2.5rem */
`;

const displayCss = css`
  ${displayMediumCss}
  ${props => props.size === 'large' && displayLargeCss}
  ${props => props.size === 'small' && displaySmallCss}
`;

const headlineLargeCss = css`
  font-size: 28px; /* 1.75rem */
  line-height: 36px; /* 2.25rem */
`;

const headlineMediumCss = css`
  font-size: 24px; /* 1.5rem */
  line-height: 32px; /* 2rem */
`;

const headlineSmallCss = css`
  font-size: 20px; /* 1.25rem */
  line-height: 30px; /* 1.875rem */
`;

const headlineCss = css`
  ${headlineMediumCss}
  ${props => props.size === 'large' && headlineLargeCss}
  ${props => props.size === 'small' && headlineSmallCss}
`;

const titleLargeCss = css`
  font-size: 18px; /* 1.125rem */
  line-height: 28px; /* 1.75rem */
`;

const titleMediumCss = css`
  font-size: 16px; /* 1rem */
  line-height: 24px; /* 1.5rem */
`;

const titleSmallCss = css`
  font-size: 14px; /* 0.875rem */
  line-height: 20px; /* 1.25rem */
`;

const titleCss = css`
  ${titleMediumCss}
  ${props => props.size === 'large' && titleLargeCss}
  ${props => props.size === 'small' && titleSmallCss}
`;

const bodyLargeCss = css`
  font-size: 16px; /* 1rem */
  line-height: 24px; /* 1.5rem */
`;

const bodyMediumCss = css`
  font-size: 14px; /* 0.875rem */
  line-height: 20px; /* 1.25rem */
`;

const bodySmallCss = css`
  font-size: 12px; /* 0.75rem */
  line-height: 18px; /* 1.125rem */
`;

const bodyCss = css`
  ${bodyMediumCss}
  ${props => props.size === 'large' && bodyLargeCss}
  ${props => props.size === 'small' && bodySmallCss}
`;

const labelLargeCss = css`
  font-size: 14px; /* 0.875rem */
  line-height: 20px; /* 1.25rem */
`;

const labelMediumCss = css`
  font-size: 12px; /* 0.75rem */
  line-height: 18px; /* 1.125rem */
`;

const labelSmallCss = css`
  font-size: 11px; /* 0.6875rem */
  line-height: 18px; /* 1.125rem */
`;

const labelCss = css`
  ${labelMediumCss}
  ${props => props.size === 'large' && labelLargeCss}
  ${props => props.size === 'small' && labelSmallCss}

  letter-spacing: 2%;
`;

export const Typography = styled.p`
  ${weightCss}
  ${bodyCss}
  ${props => props.type === 'display' && displayCss}
  ${props => props.type === 'headline' && headlineCss}
  ${props => props.type === 'title' && titleCss}
  ${props => props.type === 'label' && labelCss}
  ${props => props.color && `color: ${props.color}`}
`;

// ------------------------------
// Typography v1
// 👶 -> 2023
// ------------------------------

export const linkCss = css`
  color: ${props => props.theme.copperDark};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.copperDarkest};
  }
`;

export const Link = styled.a`
  ${linkCss}
`;

export const tableHeaderCss = css`
  color: ${props => props.theme.dark};
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

export const TableHeader = styled.p`
  ${tableHeaderCss}
`;

export const oldLabelCss = css`
  color: ${props => props.theme.darkest};
  font-size: 12px;
  line-height: 14px;
`;

export const Label = styled.p`
  ${oldLabelCss}
`;

export const labelMuteCss = css`
  color: ${props => props.theme.lightMedium};
  font-size: 12px;
  line-height: 14px;
`;

export const LabelMute = styled.p`
  ${labelMuteCss}
`;

export const labelLinkCss = css`
  color: ${props => props.theme.orange};
  font-size: 12px;
  line-height: 14px;
`;

export const LabelLink = styled.p`
  ${labelLinkCss}
`;

export const HelpTextCss = css`
  margin-top: 2px;
  margin-left: 5px;
  line-height: 16px;
  color: ${props => {
    if (props.error) return props.theme.danger;
    if (props.disabled) return props.theme.lightMedium;
    return props.theme.darkMedium;
  }};
  font-size: 12px;
`;

export const HelpText = styled.p`
  ${HelpTextCss}
`;

export const HelpTextLink = styled.p`
  ${HelpTextCss}
  margin-left: 3px;
  color: ${props => props.theme.orange};
`;

export const tagLabelCss = css`
  color: ${props => props.theme.darkest};
  font-style: normal;
  font-size: 10px;
  line-height: 15px;
`;

export const TagLabel = styled.p`
  ${tagLabelCss}
`;

export const toolTipTextCss = css`
  color: ${props => props.theme.lightest};
`;

export const ToolTipText = styled.p`
  font-size: 12px;
  ${toolTipTextCss}
`;

export const kpiNumberCss = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${props => (props.dark ? props.theme.lightest : props.theme.copperDarkest)};
`;

export const KpiNumber = styled.p`
  ${kpiNumberCss}
`;

export const CTATitleCss = css`
  color: ${props => props.theme.orange};
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
`;

export const CTATitle = styled.p`
  ${CTATitleCss}
`;

export const CTASubtitleCss = css`
  color: ${props => props.theme.lightest};
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  margin-top: 20px;
`;

export const CTASubtitle = styled.p`
  ${CTASubtitleCss}
`;

export const CTAListTitleCss = css`
  color: ${props => props.theme.lightest};
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`;

export const CTAListTitle = styled.p`
  ${CTAListTitleCss}
`;

export const CTALinkCss = css`
  color: ${props => props.theme.copperLightest};
  font-size: 13px;
  line-height: 16px;
  text-decoration: underline;
  text-underline-offset: 6px;

  &:hover {
    color: ${props => props.theme.copperDarkest};
  }
`;

export const CTALink = styled.p`
  ${CTALinkCss}
`;

export const SimPageCTASubtitleCss = css`
  color: ${props => props.theme.copperDarkest};
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 7px;
`;

export const SimPageCTASubtitle = styled.p`
  ${SimPageCTASubtitleCss}
`;

export const SimPageCTATextCss = css`
  color: ${props => props.theme.copperDarkest};
  font-size: 14px;
  line-height: 27px;
`;

export const SimPageCTAText = styled.p`
  ${SimPageCTATextCss}
`;

export const SimPageCTAListItem = styled.li`
  ${SimPageCTATextCss}
`;

export const CTAListItemCss = css`
  color: ${props => props.theme.lightest};
  font-size: 16px;
  line-height: 25px;
`;
export const CTAListItem = styled.li`
  ${CTAListItemCss}
`;

export const CTAParagraph = styled.p`
  color: ${props => props.theme.lightest};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const CTAEmphasize = styled.span`
  font-weight: 700;
  color: ${props => props.theme.copperLightest};
`;

export const ModalParagraph = styled.p`
  line-height: 23px;

  & a {
    color: ${props => props.theme.copper};
    cursor: pointer;
  }
`;

export const ModalStyledCopperP = styled(ModalParagraph)`
  font-size: 14px;
  color: ${props => props.theme.copperDarkest};
`;

export const ModalStyledDownloading = styled(ModalParagraph)`
  font-size: 14px;
  color: ${props => props.theme.copperDarkest};
  line-height: 18px;
`;

export const HeaderYearText = styled.p`
  font-weight: 400;
  font-size: 16px;
`;

export const ModalTitleCss = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${props => props.theme.darker};
`;

export const ModalTitle = styled.p`
  ${ModalTitleCss}
`;

export const CardSubTitleCss = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
`;

export const CardSubTitle = styled.p`
  ${CardSubTitleCss}
`;

export const codeTextCss = css`
  font-family: ${props => props.theme.font.code};
`;

export const signUpBigTitleCss = css`
  color: #fff;
  font-size: 3.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SignUpBigTitle = styled.span`
  ${signUpBigTitleCss}
  @media (max-width: 48rem) {
    display: none;
  }
`;

export const SignUpCardTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 30px;
  letter-spacing: -0.1px;
  text-align: center;
`;

export const signUpSubtitleCss = css`
  text-align: center;
`;

export const SignUpCardSubtitle = styled.div`
  ${signUpSubtitleCss}
  color: ${props => props.theme.darkMedium};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.42px;
  @media (max-height: 900px) {
    margin-bottom: 0.5rem;
  }
`;

export const ColorCopperDark = styled.span`
  color: ${props => props.theme.copperDark};
`;

export const helpTextErrorCss = css`
  ${HelpTextCss}
  display: flex;
  color: ${props => props.theme.danger};
`;

export const HelpTextError = styled.p`
  ${helpTextErrorCss}
`;

export const helpTextErrorLinkCss = css`
  ${helpTextErrorCss}
  text-decoration: underline;
  margin: 0 3px;
`;

export const HelpTextErrorLink = styled.p`
  ${helpTextErrorLinkCss}
`;

export const bigTitleCss = css`
  font-size: 24px;
  line-height: normal;
  font-weight: 500;
`;

export const BigTitle = styled.p`
  ${bigTitleCss}
`;

export const bigStatCss = css`
  font-size: 32px;
  font-weight: 500;
`;

export const BigStat = styled.p`
  ${bigStatCss}
`;

export const StyledCardSubTitle = styled.p`
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  font-size: 16px;
  color: ${props => props.disabled && props.theme.lightMedium};
  margin-left: ${props => props.disabled && '2px'};
`;

export const StyledPlaceholder = styled.p`
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  font-size: 14px;
  color: ${props => (props.dark ? props.theme.darker : props.theme.lightMedium)};
`;

export const CheckboxLabel = styled.p`
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
`;

export const CheckboxSubLabel = styled.p`
  line-height: normal;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.24px;
  margin-bottom: 12px;
`;

export const BoxedText = styled.p`
  padding: 0 8px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.light};
  background: ${props => props.theme.gray};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 20px;
  color: ${props => props.theme.darkMedium};
  align-self: center;
`;
