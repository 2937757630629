import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isFuture from 'date-fns/isFuture';
import subMonths from 'date-fns/subMonths';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import getDay from 'date-fns/getDay';
import styled, { css, useTheme } from 'styled-components';
import Icon from '~/components/Icon';
import { Box, Card, Flex, Grid, IconButton, Select, Text } from '@radix-ui/themes';

const DAY_BOX_SIZE = '36px';

const Wrapper = styled.div`
  position: relative;
  z-index: ${props => props.theme.zIndex.input};
`;

const Underlay = styled.div`
  position: fixed;
  z-index: ${props => props.theme.zIndex.input};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const CalenderBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.theme.radius[1]};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${props =>
    props.selected &&
    css`
      background-color: ${props => props.theme.color.orangeAlpha[4]};
    `}
  ${props =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${props => props.theme.color.neutralAlpha[3]};
      }
    `}
`;

const CalenderMonthItem = styled(CalenderBase)`
  width: 84px;
  height: 36px;
`;

const CalenderDayItem = styled(CalenderBase)`
  width: ${DAY_BOX_SIZE};
  height: ${DAY_BOX_SIZE};
`;

const CalenderItemText = styled(Text)`
  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.color.neutral[8]};
    `}
`;

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const DAYS = ['Ma', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

function MonthPicker({
  withDays,
  value,
  onChange,
  minYear = 2018,
  minMonth = false,
  minDay = false,
  limitMonths = false,
}) {
  const today = new Date();
  const [year, month, day] = value.split('-').map(s => Number(s));
  const [currentYear, setCurrentYear] = useState(year);
  const [isOpen, setIsOpen] = useState(false);
  const [daysIsOpen, setDaysIsOpen] = useState(false);
  const [tempMonth, setTempMonth] = useState(month);
  const jsMonth = withDays ? tempMonth - 1 : month - 1;
  const days = [...Array(getDaysInMonth(new Date(year, jsMonth))).keys()].map(d => d + 1);
  const firstDayOfTheMonth = new Date(currentYear, jsMonth, 1);
  const indexOfFirstDayOfTheMonth = getDay(firstDayOfTheMonth);
  const theme = useTheme();

  function SunSatToMonSunConverter(dayOfWeekNumber) {
    // getting empty days to put in the day picker before the 1st day of the month
    // passing as a prop is an index of a weekday 0 - 6 (sunday being 0)
    // and in the day picker the days start with Monday (sunday being 6)
    if (dayOfWeekNumber === 1) {
      // here prop is Monday so there should be 0 empty cells in the day picker
      return 0;
    }
    if (dayOfWeekNumber === 0) {
      // here prop is Sunday so there should be 6 empty cells in the day picker
      return 6;
    }
    // for the rest of the days we remove 1 and we get the number of the empty cells in the day picker
    return dayOfWeekNumber - 1;
  }

  if (limitMonths) {
    const limit = subMonths(today, limitMonths);
    minYear = limit.getFullYear();
    minMonth = limit.getMonth() + 1;
  }

  function setValue(newMonth) {
    setIsOpen(false);
    if (withDays) {
      setDaysIsOpen(true);
      setTempMonth(newMonth);
    } else {
      onChange(`${currentYear}-${newMonth.toString().padStart(2, '0')}`);
    }
  }

  function setDayValue(day) {
    onChange(
      `${currentYear}-${tempMonth.toString().padStart(2, '0')}${day ? `-${day.toString().padStart(2, '0')}` : ''}`,
    );
    setIsOpen(false);
    setDaysIsOpen(false);
  }

  function toggleMonths() {
    setIsOpen(!isOpen);
    setDaysIsOpen(false);
  }

  function toggleDays() {
    setDaysIsOpen(!daysIsOpen);
    setIsOpen(false);
  }

  const closePopup = useCallback(() => {
    setIsOpen(false);
    setDaysIsOpen(false);
  }, []);

  const onKeyDown = useCallback(
    e => {
      if (e.key === 'Escape') {
        closePopup();
      }
    },
    [closePopup],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  return (
    <Flex gap='2'>
      {(isOpen || daysIsOpen) && <Underlay onClick={closePopup} />}
      <Wrapper
        active={isOpen}
        withDays={withDays}
      >
        <Select.Root value='Hack to make the trigger show its content'>
          <Box
            asChild
            width='220px'
          >
            <Select.Trigger onClick={toggleMonths}>{`${year} - ${MONTHS[jsMonth]}`}</Select.Trigger>
          </Box>
        </Select.Root>
        {isOpen && (
          <Box
            mt='1'
            position='absolute'
            asChild
            width='276px'
          >
            <Card>
              <Flex
                direction='column'
                gap='3'
              >
                <Flex
                  justify='between'
                  align='center'
                >
                  <IconButton
                    size='1'
                    disabled={minYear === currentYear}
                    onClick={() => setCurrentYear(currentYear - 1)}
                    color='gray'
                    variant='outline'
                  >
                    <Icon
                      name='chevron-left'
                      width={16}
                      height={16}
                      inline
                      color={minYear === currentYear ? theme.color.neutralAlpha[8] : theme.color.neutral[12]}
                    />
                  </IconButton>
                  <Text
                    size='3'
                    weight='bold'
                  >
                    {currentYear}
                  </Text>
                  <IconButton
                    size='1'
                    disabled={currentYear === today.getFullYear()}
                    onClick={() => setCurrentYear(currentYear + 1)}
                    color='gray'
                    variant='outline'
                  >
                    <Icon
                      name='chevron-right'
                      width={16}
                      height={16}
                      inline
                      color={currentYear === today.getFullYear() ? theme.color.neutral[8] : theme.color.neutral[12]}
                    />
                  </IconButton>
                </Flex>
                <Grid
                  columns='3'
                  wrap='wrap'
                  justify='between'
                >
                  {MONTHS.map((month, monthIdx) => {
                    const disabled =
                      isFuture(new Date(currentYear, monthIdx)) || (minYear === currentYear && minMonth > monthIdx + 1);
                    const selected = year === currentYear && monthIdx === jsMonth;

                    return (
                      <CalenderMonthItem
                        key={month}
                        selected={selected}
                        onClick={() => !disabled && setValue(monthIdx + 1)}
                        disabled={disabled}
                      >
                        <CalenderItemText
                          disabled={disabled}
                          weight={selected ? 'bold' : 'normal'}
                        >
                          {month}
                        </CalenderItemText>
                      </CalenderMonthItem>
                    );
                  })}
                </Grid>
              </Flex>
            </Card>
          </Box>
        )}
      </Wrapper>

      {withDays && (
        <Wrapper active={daysIsOpen}>
          <Select.Root value='Hack to make the trigger show its content'>
            <Box
              asChild
              width='220px'
            >
              <Select.Trigger onClick={toggleDays}>{day}</Select.Trigger>
            </Box>
          </Select.Root>
          {daysIsOpen && (
            <Box
              mt='1'
              position='absolute'
              asChild
              width='264px'
            >
              <Card>
                <Flex
                  direction='column'
                  gap='1'
                >
                  <Grid columns='7'>
                    {DAYS.map(day => {
                      return (
                        <CalenderDayItem key={day}>
                          <CalenderItemText>{day}</CalenderItemText>
                        </CalenderDayItem>
                      );
                    })}
                  </Grid>
                  <Grid columns='7'>
                    {Array(SunSatToMonSunConverter(indexOfFirstDayOfTheMonth)).fill(
                      <Box
                        width={DAY_BOX_SIZE}
                        height={DAY_BOX_SIZE}
                      />,
                    )}
                    {days.map(monthlyDay => {
                      const isInTheFuture = isFuture(new Date(currentYear, jsMonth, monthlyDay));
                      const disabled =
                        isInTheFuture || (minYear === currentYear && minMonth === month && minDay > monthlyDay);
                      const selected = year === currentYear && month === jsMonth + 1 && monthlyDay === day;

                      return (
                        <CalenderDayItem
                          key={monthlyDay}
                          disabled={disabled}
                          onClick={() => !disabled && setDayValue(monthlyDay)}
                          selected={selected}
                        >
                          <CalenderItemText
                            disabled={disabled}
                            weight={selected ? 'bold' : 'normal'}
                          >
                            {monthlyDay}
                          </CalenderItemText>
                        </CalenderDayItem>
                      );
                    })}
                  </Grid>
                </Flex>
              </Card>
            </Box>
          )}
        </Wrapper>
      )}
    </Flex>
  );
}

MonthPicker.propTypes = {
  withDays: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  minYear: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  minMonth: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  minDay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  limitMonths: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default MonthPicker;
