import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StatusIndicator = styled.div`
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: var(--radius-6);
  border: 1px solid var(--neutral-3);

  ${({ type }) =>
    type === 'online' &&
    css`
      background-color: var(--success-a10);
    `};
  ${({ type }) =>
    type === 'offline' &&
    css`
      background-color: var(--neutral-a8);
    `};
  ${({ type }) =>
    type === 'operational' &&
    css`
      background-color: var(--success-a10);
    `};
  ${({ type }) =>
    type === 'degraded_performance' &&
    css`
      background-color: var(--warning-a10);
    `};
  ${({ type }) =>
    type === 'partial_outage' &&
    css`
      background-color: var(--warning-a10);
    `};
  ${({ type }) =>
    type === 'major_outage' &&
    css`
      background-color: var(--error-a10);
    `};
  ${({ type }) =>
    type === 'under_maintenance' &&
    css`
      background-color: var(--info-a10);
    `};
`;

StatusIndicator.propTypes = {
  type: PropTypes.oneOf([
    'online',
    'offline',
    'operational',
    'degraded_performance',
    'partial_outage',
    'major_outage',
    'under_maintenance',
  ]).isRequired,
};

export default StatusIndicator;
