import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useAuthentication from '~/hooks/use-authentication';
import useTiering from '~/hooks/use-tiering';
import useDebounce from '~/hooks/use-debounce';
import useSearchSimsFilters from '~/hooks/use-search-sims-filters';
import api, { useConfig } from '~/lib/api';
import { toApi, fromApi } from '~/lib/data-parsers/sim';
import { transformToUtc } from '~/lib/dates';
import startOfMonth from 'date-fns/startOfMonth';

const DEFAULT_PAGES = {
  limit: 20,
  offset: 0,
  currentPage: 1,
  isPaging: false,
};

function useSims({ organizationId, disableFetchSimsOnChange = false, disableSetUrlParamsOnSearch = false, from }) {
  const location = useLocation();

  const queryString = new URLSearchParams(location.search);
  const searchParam = queryString.get('search') || '';
  const filterParam = queryString.getAll('filter');
  const sortParam = queryString.get('sort');
  const pageParam = queryString.get('page');
  function fromApiFilter(key, value) {
    const newKey = key.replace('network_whitelist', 'networkWhitelist');
    const newValue = value.replace('none', 'None');
    return { key: newKey, value: newValue };
  }

  let initialFilter = [];
  if (filterParam) {
    const newFilterArray = [];
    filterParam.map(filter => {
      const [key, value] = filter.split(':');
      return newFilterArray.push(fromApiFilter(key, value));
    });
    initialFilter = newFilterArray;
  }

  const initialOffset = pageParam ? (Number(pageParam) - 1) * DEFAULT_PAGES.limit : DEFAULT_PAGES.offset;
  const initialPage = {
    limit: DEFAULT_PAGES.limit,
    total: DEFAULT_PAGES.total,
    totalPages: DEFAULT_PAGES.totalPages,
    currentPage: pageParam ? Number(pageParam) : DEFAULT_PAGES.currentPage,
    isPaging: false,
  };

  const history = useHistory();
  const { isLoggedIn, assumedOrganizationId, isOrgMember } = useAuthentication();
  const { hasFeature } = useTiering();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [pages, setPages] = useState(initialPage);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(initialOffset);
  const [search, setSearch] = useState(searchParam);
  const [searchLoading, setSearchLoading] = useState(false);
  const [order, setOrder] = useState(sortParam || null);
  const debouncedSearch = useDebounce(search);
  const createConfig = useConfig(organizationId || assumedOrganizationId);
  const filters = useSearchSimsFilters(
    organizationId || assumedOrganizationId,
    initialFilter && initialFilter.length > 0 ? initialFilter : null,
  );
  const [filter, setFilter] = useState(initialFilter);
  const [affectAll, setAffectAll] = useState(false);

  function toApiSort(order) {
    return order.replace('networkWhitelist', 'network_whitelist');
  }

  function toApiFilter(key, value) {
    let newKey = key;
    if (key === 'networkWhitelist') newKey = key.replace('networkWhitelist', 'network_whitelist');
    if (key === 'isSoftSim') newKey = key.replace('isSoftSim', 'is_softsim');
    const newValue = value.replace('None', 'none');
    return `${newKey}:${newValue}`;
  }

  function convertFilter(filterArray) {
    return filterArray.map(filter => toApiFilter(filter.key, filter.value));
  }

  function getPathName(search, filter, order, pages) {
    const params = new URLSearchParams();
    if (search) params.set('search', search);
    if (filter?.length > 0) {
      filter.forEach(filter => params.append('filter', `${filter.key}:${filter.value}`));
    }
    if (order) params.set('sort', order);
    if (offset / pages.limit + 1 !== 1) params.set('page', offset / pages.limit + 1);
    if (offset / pages.limit + 1 === 1) params.delete('page');
    return `${location.pathname}?${params.toString()}`;
  }

  async function searchSims() {
    if (!isLoggedIn) return;
    try {
      const config = {
        params: {
          limit: pages.limit,
          offset,
          start: transformToUtc(startOfMonth(new Date())),
          end: transformToUtc(new Date()),
        },
      };
      if (search) {
        config.params.search = search;
      }
      if (filter) {
        config.params.filter = convertFilter(filter);
      }
      if (order) {
        config.params.sort = toApiSort(order);
      }
      const response = await api.get('/sims/find', createConfig(config));
      if (!disableSetUrlParamsOnSearch) {
        history.replace(getPathName(search, filter, order, pages));
      }
      setData(response.data.sims.map(s => fromApi(s, isOrgMember)));
      setPages({
        isPaging: false,
        limit: response.data.limit,
        total,
        totalPages: Math.ceil(total / response.data.limit),
        currentPage: offset / response.data.limit + 1,
      });
      setSearchLoading(false);
      setLoading(false);
      if (search) {
        mixpanel.track('Search sims', {
          'Search query': search,
          From: from,
          '# Total search results': response.data.total,
          '# Search results': response.data.count,
        });
      }
      if (filter) {
        mixpanel.track('Filter sims', {
          'Active filter': filter.key,
          'Filter value': filter.value,
          '# Total results': response.data.total,
        });
      }
    } catch (e) {
      setError(e);
      setSearchLoading(false);
      setLoading(false);
    }
  }

  async function get() {
    if (!isLoggedIn) return;
    try {
      const config = {
        params: {
          limit: pages.limit,
          offset,
          start: transformToUtc(startOfMonth(new Date())),
          end: transformToUtc(new Date()),
        },
      };
      if (filter) {
        config.params.filter = convertFilter(filter);
      }
      if (order) {
        config.params.sort = toApiSort(order);
      }
      const response = await api.get('/sims', createConfig(config));
      history.replace(getPathName(search, filter, order, pages));
      setData(response.data.sims.map(s => fromApi(s, isOrgMember)));
      setPages({
        isPaging: false,
        limit: response.data.limit,
        total: response.data.total,
        totalPages: Math.ceil(response.data.total / response.data.limit),
        currentPage: offset / response.data.limit + 1,
      });
      if (search) {
        mixpanel.track('Search sims', {
          'Search query': search,
          From: from,
          '# Total search results': total,
          '# Search results': response.data.count,
        });
      }
      if (filter) {
        mixpanel.track('Filter sims', {
          'Active filter': filter.key,
          'Filter value': filter.value,
          '# Total results': total,
        });
      }
      setLoading(false);
    } catch (e) {
      setError(e);
      setSearchLoading(false);
      setLoading(false);
    }
  }

  async function getDetails(sim) {
    const response = await api.get(`/sims/${sim.id}`, createConfig());
    setData(
      data.map(s => {
        if (s.id !== sim.id) return s;
        return fromApi(response.data, isOrgMember);
      }),
    );
    return fromApi(response.data, isOrgMember);
  }

  async function create(sim) {
    const formattedTechFee = !sim.techFee ? null : sim.techFee;

    const payload = {
      id: sim.id,
      apn_profile: sim.apnProfile,
      tech_fee: formattedTechFee,
    };
    const response = await api.post('/sims', payload, createConfig());
    setData([...data, fromApi(response.data, isOrgMember)].sort((a, b) => Number(a.id) - Number(b.id)));
  }

  async function update(sim, trackRequest = true) {
    const response = await api.patch(
      `/sims/${sim.id}`,
      toApi(sim, isOrgMember, hasFeature),
      createConfig({
        params: {
          track: trackRequest,
        },
      }),
    );
    const updatedSim = fromApi(response.data, isOrgMember);
    setData(
      data.map(r => {
        if (r.id !== sim.id) return r;
        // TODO remove the following line and change the subsequent one as this is a patch introduced 2023-08-23 due to a broken API change
        const previousUsage = sim.usage;
        return { ...updatedSim, usage: previousUsage };
      }),
    );
  }

  async function bulkAction({ addTags, removeTags }) {
    const config = createConfig({ params: { search } });
    const payload = {
      add: addTags.map(t => t.value),
      remove: removeTags.map(t => t.value),
      sims: affectAll ? null : data.filter(s => s.checked).map(s => s.id),
    };
    const response = await api.patch('/sims/set-delta', payload, config);
    (await search) ? searchSims() : get();
    return response.data;
  }

  function orderChange(key, orderAcronym) {
    setOrder(orderAcronym ? `${key}:${orderAcronym}` : null);
  }

  function nextPage() {
    if (pages.isPaging) return;
    const nextOffset = offset + pages.limit;
    if (nextOffset >= pages.total) return;
    setPages({
      ...pages,
      isPaging: true,
    });
    setOffset(nextOffset);
    mixpanel.track('Paginate sims forwards', {
      'Page offset': offset,
    });
  }

  function prevPage() {
    if (pages.isPaging) return;
    const nextOffset = offset - pages.limit;
    if (nextOffset < 0) return;
    setPages({
      ...pages,
      isPaging: true,
    });
    setOffset(nextOffset);
    mixpanel.track('Paginate sims backwards', {
      'Page offset': pages.offset,
    });
  }

  useEffect(() => {
    setSearchLoading(search?.length > 0);
  }, [search]);

  useEffect(() => {
    if (search?.length >= 1) {
      setLoading(true);
      searchSims();
      return;
    }
    if (disableFetchSimsOnChange) return;
    setLoading(true);
    get();
  }, [isLoggedIn, offset, debouncedSearch, JSON.stringify(filter), assumedOrganizationId, order]);

  useEffect(() => {
    async function getTotal(filter, search) {
      const config = { params: { filter, search: search || undefined } };
      const res = await api.get('/sims/count', createConfig(config));
      const total = res.data.total;
      setTotal(total);
      setPages({ ...pages, offset: 0 });
    }
    const countFromFilter = convertFilter(filter);
    getTotal(countFromFilter, debouncedSearch);
  }, [JSON.stringify(filter), debouncedSearch]);

  return {
    loading,
    error,
    data,
    create,
    update,
    getDetails,
    bulkEdit: {
      toggleItem(sim) {
        setData(prevState => prevState.map(s => (s.id === sim.id ? { ...s, checked: !s.checked } : s)));
      },
      toggleAll(checked) {
        setData(prevState => prevState.map(sim => ({ ...sim, checked: checked })));
      },
      bulkAction,
      setAffectAll,
      affectAll,
    },
    pagination: {
      nextPage,
      prevPage,
      pages: { ...pages, total, totalPages: Math.ceil(total / pages.limit) },
    },
    search: {
      value: search,
      onChange(e) {
        setOffset(0);
        setSearch(e.target.value);
      },
      clear() {
        setSearch('');
        setData(null);
      },
      reset() {
        setSearch('');
        filters.clear();
        setOrder(null);
        setPages({ ...DEFAULT_PAGES, currentPage: 1 });
      },
      loading: searchLoading,
      placeholder: 'Search by ID, label, tag...',
      width: '220px',
    },
    filters: {
      ...filters,
      apply() {
        setFilter(filters.activeFilters);
      },
    },
    order: {
      order,
      orderChange,
    },
  };
}

export default useSims;
