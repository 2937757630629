import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ThickCheckIcon,
  Button,
  Popover,
  TextField,
  Flex,
  Badge,
  Text,
  Tooltip,
  Separator,
  Kbd,
} from '@radix-ui/themes';
import styled from 'styled-components';
import {
  Item,
  StyledButton,
  StyledChevronDownIcon,
  StyledIcon,
  StyledIconWithPointer,
  StyledPlaceholder,
  StyledPopoverContent,
  StyledTextMultiple,
  StyledTextSingle,
} from './elements';
import { useCombobox } from 'downshift';
import useOnClickOutside from '~/hooks/use-on-click-outside';
import useOS from '~/hooks/use-os';
import { propTypes } from '../Input/Elements/InputWrapper';
import { ColorPropType } from './propTypes';

const BADGE_ICON_SIZE_SETTINGS_1_AND_2 = {
  name: 'x-badge-size-1-&-2',
  height: 12,
  width: 12,
};

const BADGE_ICON_SIZE_SETTINGS = {
  1: BADGE_ICON_SIZE_SETTINGS_1_AND_2,
  2: BADGE_ICON_SIZE_SETTINGS_1_AND_2,
  3: {
    name: 'x-badge-size-3',
    height: 16,
    width: 16,
  },
};

const PLUS_ICON_SIZE_SETTINGS_1_AND_2 = {
  name: 'plus-badge-size-1-&-2',
  height: 12,
  width: 12,
};

const PLUS_ICON_SIZE_SETTINGS = {
  1: PLUS_ICON_SIZE_SETTINGS_1_AND_2,
  2: PLUS_ICON_SIZE_SETTINGS_1_AND_2,
  3: {
    name: 'plus-badge-size-3',
    height: 16,
    width: 16,
  },
};

const EMPTY_SELECTION_ARRAY = [];
const INITIAL_SEARCH_VALUE = '';

const POPOVER_CLOSE_ANIMATION_DURATION = 500;

export const ItemPropType = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

function LeftoverItemsTooltip({ leftoverItems = [] }) {
  return (
    <Flex direction='column'>
      {leftoverItems.map(item => (
        <Text
          key={item.value}
          size='1'
        >
          {item.label}
        </Text>
      ))}
    </Flex>
  );
}

LeftoverItemsTooltip.propTypes = {
  leftoverItems: PropTypes.arrayOf(ItemPropType),
};

const StyledTooltipBox = styled(Box)`
  z-index: ${props => props.theme.zIndex.modal + 2};
`;

function RenderTooltip({ content, showTooltip, children }) {
  if (!showTooltip) return children;
  return <Tooltip content={<StyledTooltipBox>{content}</StyledTooltipBox>}>{children}</Tooltip>;
}

RenderTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showTooltip: PropTypes.bool,
  children: PropTypes.node,
};

function SelectedItem({ item, onSelect, size, togglePopover }) {
  const ref = useRef();
  const showTooltip = !!ref.current && ref.current.offsetWidth < ref.current.scrollWidth;

  return (
    <RenderTooltip
      content={item.label}
      showTooltip={showTooltip}
    >
      <Badge
        key={item.value}
        size={size === '3' ? '2' : '1'}
        variant='soft'
        onClick={e => {
          e.stopPropagation();
          togglePopover();
        }}
        color='gray'
      >
        <Flex
          align='center'
          gap='1'
        >
          <StyledTextMultiple
            ref={ref}
            weight='bold'
            truncate
          >
            {item.label}
          </StyledTextMultiple>
          <StyledIconWithPointer
            inline
            onClick={e => {
              e.stopPropagation();
              onSelect(item.value);
            }}
            color='gray'
            {...BADGE_ICON_SIZE_SETTINGS[size]}
          />
        </Flex>
      </Badge>
    </RenderTooltip>
  );
}

SelectedItem.propTypes = {
  item: ItemPropType,
  onSelect: PropTypes.func,
  size: PropTypes.oneOf(['1', '2', '3']),
  togglePopover: PropTypes.func,
};

function SelectedItems({ selected, onSelect, size, maxVisibleItems, togglePopover }) {
  const renderItems = maxVisibleItems ? selected.slice(0, maxVisibleItems) : selected;
  const hiddenItems = maxVisibleItems ? selected.slice(maxVisibleItems) : [];

  return (
    <Flex
      gap='1'
      overflowX='hidden'
    >
      {renderItems.map(item => {
        return (
          <SelectedItem
            key={item.value}
            item={item}
            onSelect={onSelect}
            size={size}
            togglePopover={togglePopover}
          />
        );
      })}
      {hiddenItems.length > 0 && (
        <Tooltip content={<LeftoverItemsTooltip leftoverItems={hiddenItems} />}>
          <Badge
            size={size === '3' ? '2' : '1'}
            variant='soft'
            onClick={e => e.stopPropagation()}
          >
            <Flex
              align='center'
              gap='1'
            >
              <StyledIcon
                color='gray'
                {...PLUS_ICON_SIZE_SETTINGS[size]}
              />
              <Text weight='bold'>{hiddenItems.length}</Text>
            </Flex>
          </Badge>
        </Tooltip>
      )}
    </Flex>
  );
}

SelectedItems.propTypes = {
  selected: PropTypes.arrayOf(ItemPropType),
  onSelect: PropTypes.func,
  size: PropTypes.oneOf(['1', '2', '3']),
  maxVisibleItems: PropTypes.number,
  togglePopover: PropTypes.func,
};

function RenderSingleSelectedItem({ label }) {
  const ref = useRef();
  const showTooltip = !!ref.current && ref.current.offsetWidth < ref.current.scrollWidth;

  return (
    <RenderTooltip
      content={label}
      showTooltip={showTooltip}
    >
      <StyledTextSingle
        ref={ref}
        color='gray'
        truncate
        weight='regular'
      >
        {label}
      </StyledTextSingle>
    </RenderTooltip>
  );
}

RenderSingleSelectedItem.propTypes = {
  label: PropTypes.string,
};

function RenderSelectContent({ selected, placeholder, multiple, onSelect, maxVisibleItems, size, togglePopover }) {
  if (selected.length === 0)
    return (
      <StyledPlaceholder
        color='gray'
        weight='regular'
      >
        {placeholder}
      </StyledPlaceholder>
    );
  if (multiple)
    return (
      <SelectedItems
        selected={selected}
        onSelect={onSelect}
        maxVisibleItems={maxVisibleItems}
        size={size}
        togglePopover={togglePopover}
      />
    );
  return <RenderSingleSelectedItem label={selected[0].label} />;
}

RenderSelectContent.propTypes = {
  selected: PropTypes.arrayOf(ItemPropType),
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  onSelect: PropTypes.func,
  maxVisibleItems: PropTypes.number,
  size: PropTypes.oneOf(['1', '2', '3']),
  togglePopover: PropTypes.func,
};

function ComboboxComponent({
  closeOnSelect,
  color = 'accent',
  disabled,
  emptyContent = 'No items found.',
  items = [],
  maxVisibleItems,
  maxVisibleOptions = 6,
  multiple = false,
  onSearchChange,
  onSelectItem,
  onSelectedChange,
  onDeselectItem,
  placeholder = 'Select...',
  search: externalSearch,
  selected,
  showSearch = true,
  size = '2',
  variant = 'outline',
  width = '100%',
}) {
  const standardizedSelected = useMemo(() => {
    if (!selected) return [];
    return Array.isArray(selected)
      ? selected.map(s => (typeof s === 'object' ? s : { label: s, value: s }))
      : typeof selected === 'object'
      ? [selected]
      : [{ label: selected, value: selected }];
  }, [selected]);

  const itemsArrayOfObjects = useMemo(
    () => items.map(i => (typeof i === 'string' ? { label: i, value: i } : i)),
    [items],
  );

  const [itemsSelected, setItemsSelected] = useState(EMPTY_SELECTION_ARRAY);
  const [availableItems, setAvailableItems] = useState([]);

  useEffect(() => {
    function getRenderableItems() {
      if (standardizedSelected.length === 0) return itemsArrayOfObjects;

      const selectedWithoutCorespondingItem = standardizedSelected.filter(
        o => !itemsArrayOfObjects.some(i => i.value === o.value),
      );
      if (selectedWithoutCorespondingItem.length === 0) return itemsArrayOfObjects;

      return [...itemsArrayOfObjects, ...selectedWithoutCorespondingItem];
    }

    function getSelectedAsArrayOfObjects(renderableItems) {
      const itemMap = new Map(renderableItems.map(i => [i.value, i]));
      return standardizedSelected.map(s => itemMap.get(s.value));
    }

    // Sometimes, we have selected items that are not in the items list. This adds them to the items list.
    const returnItems = getRenderableItems();
    // This creates a new array of selected items in Object form to streamline the logic in the rest of the component.
    const returnSeleted = getSelectedAsArrayOfObjects(returnItems);

    setItemsSelected(returnSeleted);
    setAvailableItems(returnItems);
  }, [standardizedSelected, items, itemsArrayOfObjects]);

  const [open, setOpen] = useState(false);
  const [localSearch, setLocalSearch] = useState(INITIAL_SEARCH_VALUE);
  const search = useMemo(() => externalSearch || localSearch, [externalSearch, localSearch]);
  const setSearch = useCallback(
    value => {
      if (onSearchChange) return onSearchChange(value);
      setLocalSearch(value);
    },
    [onSearchChange],
  );

  const [showClear, setShowClear] = useState(false);
  const popoverNode = useRef();
  const buttonNode = useRef();
  const searchInputNode = useRef();
  const os = useOS();
  const metaKeyName = os.isMac ? 'cmd' : 'ctrl';

  useOnClickOutside([popoverNode, buttonNode], () => setOpen(false));

  useEffect(() => {
    if (!selected) {
      return console.warn(
        'Combobox: Expected `selected` to be a string or an array. Using the default value based on `multiple`.',
      );
    }
    if (multiple && !Array.isArray(selected)) {
      console.warn('Combobox: Expected `selected` to be an array when `multiple` is true. Auto-converting.');
    }
    if (!multiple && Array.isArray(selected)) {
      console.warn('Combobox: Expected `selected` to be a string when `multiple` is false. Using the first element.');
    }
  }, [selected, multiple]);

  const onSelect = useCallback(
    currentValue => {
      function getNewSelected(value, method) {
        if (multiple) {
          return method === 'remove'
            ? itemsSelected.filter(s => s.value !== value)
            : [...itemsSelected, availableItems.find(i => i.value === value)];
        }
        return method === 'remove' ? EMPTY_SELECTION_ARRAY : [availableItems.find(s => s.value === value)];
      }

      const shouldRemoveValue = itemsSelected.some(s => s.value === currentValue);

      if (onSelectedChange) {
        const newSelected = getNewSelected(currentValue, shouldRemoveValue ? 'remove' : 'add');

        onSelectedChange?.(multiple ? newSelected.map(s => s.value) : newSelected[0]?.value || undefined);
      }

      shouldRemoveValue ? onDeselectItem?.(currentValue) : onSelectItem?.(currentValue);

      if (closeOnSelect && !shouldRemoveValue) setOpen(false);
    },
    [itemsSelected, onSelectedChange, onDeselectItem, onSelectItem, closeOnSelect, multiple, availableItems],
  );

  function togglePopover() {
    if (disabled) return;
    setOpen(prev => !prev);
  }

  const filteredItems = useMemo(() => {
    if (!search) return availableItems;
    const lowerSearch = search.toLowerCase();
    return availableItems.filter(
      item =>
        (item.label && item.label.toLowerCase().includes(lowerSearch)) ||
        (typeof item.value === 'string' && item.value.toLowerCase().includes(lowerSearch)),
    );
  }, [search, availableItems]);

  const { getMenuProps, getInputProps, getItemProps, getComboboxProps, highlightedIndex } = useCombobox({
    items: filteredItems,
    itemToString: () => '',
    inputValue: search,
    onInputValueChange: () => {},
    onIsOpenChange: e => {
      const isItemSelected =
        e.type === useCombobox.stateChangeTypes.InputKeyDownEnter || e.type === useCombobox.stateChangeTypes.ItemClick;
      if (isItemSelected) return;
      setOpen(e.isOpen);
    },
  });

  useEffect(() => {
    setShowClear(itemsSelected.length > 0);
    searchInputNode.current && searchInputNode.current.focus();
  }, [multiple, onSelectedChange, itemsSelected]);

  function resetCombobox(e) {
    e?.preventDefault();
    setSearch(INITIAL_SEARCH_VALUE);
    onSelectedChange?.(multiple ? EMPTY_SELECTION_ARRAY : EMPTY_SELECTION_ARRAY[0]);
  }

  const inputProps = getInputProps({}, { suppressRefError: true });

  return (
    <Popover.Root
      {...getComboboxProps({}, { suppressRefError: true })}
      open={open}
      onOpenChange={isOpen => {
        if (disabled) return;
        if (!isOpen) setTimeout(() => setSearch(''), POPOVER_CLOSE_ANIMATION_DURATION);
      }}
    >
      <Popover.Trigger asChild>
        <Box
          width={width}
          asChild
        >
          <StyledButton
            ref={buttonNode}
            size={size}
            variant={variant}
            color={color}
            asChild
            disabled={disabled}
            tabIndex={disabled ? -1 : 0}
            onClick={togglePopover}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === 'ArrowDown' || e.key === 'ArrowUp') togglePopover();
            }}
          >
            <Flex
              gap='2'
              justify='between'
            >
              <RenderSelectContent
                items={availableItems}
                selected={itemsSelected}
                placeholder={placeholder}
                multiple={multiple}
                onSelect={onSelect}
                maxVisibleItems={maxVisibleItems}
                size={size}
                togglePopover={togglePopover}
              />
              <StyledChevronDownIcon color='gray' />
            </Flex>
          </StyledButton>
        </Box>
      </Popover.Trigger>
      <StyledPopoverContent
        ref={popoverNode}
        onOpenAutoFocus={e => e.preventDefault()}
        onMouseDown={e => e.preventDefault()}
      >
        <Flex
          direction='column'
          gap='1'
        >
          {showSearch && (
            <TextField.Root
              ref={searchInputNode}
              autoFocus
              color='gray'
              size={size}
              placeholder='Search...'
              {...inputProps}
              onChange={e => setSearch(e.target.value)}
              onKeyDown={e => {
                const highlightedItem = filteredItems[highlightedIndex];
                if (e.key === 'Enter' && !!highlightedItem) return onSelect(highlightedItem.value);
                if (e.key === 'Escape') return setOpen(false);
                if (e.metaKey && e.key === 'c') return resetCombobox();
                inputProps.onKeyDown(e);
              }}
            />
          )}
          <Flex
            direction='column'
            {...getMenuProps({}, { suppressRefError: true })}
            onClick={e => e.stopPropagation()}
            maxHeight={`${maxVisibleOptions * 32}px`}
            overflowY='auto'
          >
            {filteredItems.length === 0 && (
              <Box height={size === '1' ? '24px' : '32px'}>
                <Flex
                  align='center'
                  justify='center'
                  height='100%'
                >
                  <Text size={size === '1' ? '1' : '2'}>{emptyContent}</Text>
                </Flex>
              </Box>
            )}
            {filteredItems.map((item, index) => {
              const isChecked = itemsSelected.some(s => s.value === item.value);

              return (
                <Item
                  align='center'
                  minHeight={size === '1' ? '24px' : '32px'}
                  key={item.value}
                  color={color}
                  value={item.value}
                  checked={isChecked}
                  data-checked={isChecked}
                  data-highlighted={highlightedIndex === index}
                  {...getItemProps({
                    item,
                    index,
                    onClick: () => onSelect(item.value),
                  })}
                >
                  <Flex
                    gap='2'
                    px={size === '1' ? '2' : '3'}
                    width='100%'
                    align='center'
                    justify='between'
                  >
                    <Text size={size === '1' ? '1' : '2'}>{item.label}</Text>
                    {isChecked && <ThickCheckIcon />}
                  </Flex>
                </Item>
              );
            })}
          </Flex>
          {showClear && !!onSelectedChange && (
            <>
              <Separator
                size='4'
                my='2'
              />
              <Box
                mt='2'
                width='100%'
                asChild
              >
                <Button
                  size={size}
                  variant='ghost'
                  onMouseDown={resetCombobox}
                >
                  <Text
                    weight='bold'
                    color='gray'
                  >
                    Clear <Kbd>{metaKeyName} + c</Kbd>
                  </Text>
                </Button>
              </Box>
            </>
          )}
        </Flex>
      </StyledPopoverContent>
    </Popover.Root>
  );
}

export const ComboboxComponentPropTypes = {
  closeOnSelect: PropTypes.bool,
  color: ColorPropType,
  disabled: PropTypes.bool,
  emptyContent: PropTypes.string,
  items: PropTypes.arrayOf(ItemPropType),
  maxVisibleItems: PropTypes.number,
  maxVisibleOptions: PropTypes.number,
  multiple: PropTypes.bool,
  onSearchChange: PropTypes.func,
  onSelectItem: PropTypes.func,
  onSelectedChange: PropTypes.func,
  onDeselectItem: PropTypes.func,
  placeholder: PropTypes.string,
  search: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    propTypes.number,
    ItemPropType,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, propTypes.number, ItemPropType])),
  ]),
  showSearch: PropTypes.bool,
  size: PropTypes.oneOf(['1', '2', '3']),
  variant: PropTypes.oneOf(['solid', 'ghost', 'outline', 'soft']),
  width: PropTypes.string,
};

ComboboxComponent.propTypes = ComboboxComponentPropTypes;

export default ComboboxComponent;
