import React from 'react';
import { NavLink } from 'react-router-dom';
import HealthStatus from '~/components/HealthStatus';
import Icon from '~/components/Icon';
import { Box, Flex, Grid, Text, Button, DropdownMenu, Select, Link, Switch, Callout } from '@radix-ui/themes';
import Logo from '~/components/Logo';
import PropTypes from 'prop-types';
import { CLERK_SIGN_IN_VARIABLES } from '~/pages/Login';
import Combobox from '~/components/Combobox';

const MAX_WIDTH = { initial: '375px', xs: '960px', md: '1280px' };

function DropdownMenuItemContentWithIcon({ iconName, label }) {
  return (
    <Flex
      gap='2'
      align='center'
    >
      <Icon
        name={iconName}
        width={16}
        height={16}
      />
      <Text
        highContrast
        wrap='nowrap'
      >
        {label}
      </Text>
    </Flex>
  );
}

DropdownMenuItemContentWithIcon.propTypes = {
  iconName: PropTypes.string,
  label: PropTypes.string,
};

function Top({
  clipboard,
  clerk: { openUserProfile, getToken },
  theme: { currentTheme, switchTheme },
  userInfo: {
    email,
    isOwner,
    isDemiGod,
    isGod,
    assumeOrganizationId,
    assumedOrganizationId,
    toggleIsViewingAsAssumedOrganization,
    usersOrganizations,
    usersCurrentOrganizationId,
    changeOrganization,
  },
  status,
  organizations,
}) {
  const organizationsItems = organizations.loading
    ? [{ label: 'Loading', value: null }]
    : organizations.data
    ? organizations.data.map(a => ({
        label: `${a.id} - ${a.entity}`,
        value: a.id,
      }))
    : [];

  async function copyClerkToken() {
    const clerkToken = await getToken({ template: process.env.CLERK_LONGER_LASTING_TOKEN_TEMPLATE });
    clipboard.write(clerkToken);
  }

  return (
    <>
      {assumedOrganizationId !== null && (
        <Flex
          asChild
          width='100%'
          justify='center'
          p='1'
        >
          <Callout.Root
            size='1'
            color='yellow'
          >
            <Callout.Text
              size='1'
              color='yellow'
            >
              Any changes made in Admin view will be applied live
            </Callout.Text>
          </Callout.Root>
        </Flex>
      )}
      <Flex
        width='100%'
        justify='center'
      >
        <Grid
          columns={{ initial: '1', xs: '2' }}
          width='100%'
          maxWidth={MAX_WIDTH}
          p='4'
        >
          <Flex
            justify='start'
            align='center'
            gap='4'
            gridColumn='1/3'
          >
            <Flex
              height='100%'
              align='start'
              pb='1'
            >
              <Logo />
            </Flex>
            <HealthStatus {...status} />
          </Flex>

          <Flex
            gridColumn='5/3'
            justify='end'
          >
            {(isDemiGod || isGod) && (
              <Flex
                pr='5'
                gap='4'
                align='center'
              >
                <Text
                  as='label'
                  size='2'
                >
                  <Flex gap='2'>
                    <Switch
                      disabled={!assumedOrganizationId}
                      onClick={toggleIsViewingAsAssumedOrganization}
                      size='1'
                    />
                    Assumed org.
                  </Flex>
                </Text>
                <Button
                  size='1'
                  variant='ghost'
                  color='gray'
                  onClick={() => copyClerkToken()}
                >
                  <Icon
                    name='copy'
                    height={16}
                    width={16}
                    color='currentColor'
                  />
                  Token
                </Button>
                <>
                  <Combobox
                    items={organizationsItems}
                    selected={assumedOrganizationId || ''}
                    onSelectedChange={assumeOrganizationId}
                    size='1'
                    placeholder='Choose organization'
                    width='164px'
                    color='gray'
                  />
                  <Button
                    asChild
                    variant='ghost'
                    color='gray'
                    size='1'
                  >
                    <NavLink to='/admin'>
                      <Flex
                        gap='2'
                        align='center'
                      >
                        <Icon
                          name='settings-01'
                          width={16}
                          height={16}
                          color='currentColor'
                        />
                        <Text wrap='nowrap'>Admin</Text>
                      </Flex>
                    </NavLink>
                  </Button>
                </>
              </Flex>
            )}

            <Flex align='center'>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <Button
                    variant='ghost'
                    color='gray'
                    size='1'
                    highContrast
                  >
                    {email}
                    <DropdownMenu.TriggerIcon />
                  </Button>
                </DropdownMenu.Trigger>

                <Box
                  width='180px'
                  asChild
                >
                  <DropdownMenu.Content
                    color='gray'
                    variant='soft'
                  >
                    {usersOrganizations.length === 0 ? (
                      <strong>{usersOrganizations?.[0].name}</strong>
                    ) : (
                      <Select.Root
                        value={usersCurrentOrganizationId}
                        onValueChange={changeOrganization}
                      >
                        <Box
                          maxWidth='164px'
                          asChild
                        >
                          <Select.Trigger />
                        </Box>
                        <Select.Content position='popper'>
                          {usersOrganizations.map(o => {
                            return (
                              <Select.Item
                                key={o?.id}
                                value={o?.id}
                              >
                                {o?.name}
                              </Select.Item>
                            );
                          })}
                        </Select.Content>
                      </Select.Root>
                    )}
                    <DropdownMenu.Separator />
                    <DropdownMenu.Item
                      onClick={() =>
                        openUserProfile({ appearance: { variables: CLERK_SIGN_IN_VARIABLES }, routing: 'virtual' })
                      }
                    >
                      <DropdownMenuItemContentWithIcon
                        iconName='settings-01'
                        label='Settings'
                      />
                    </DropdownMenu.Item>
                    {(isOwner || isDemiGod || isGod) && (
                      <DropdownMenu.Item asChild>
                        <NavLink to='/invoices'>
                          <DropdownMenuItemContentWithIcon
                            iconName='receipt'
                            label='Invoices'
                          />
                        </NavLink>
                      </DropdownMenu.Item>
                    )}
                    {[
                      { label: 'Knowledge base', iconName: 'book-open-01', href: 'https://help.onomondo.com' },
                      { label: 'API docs', iconName: 'file-code-01', href: 'https://docs.onomondo.com' },
                    ].map(item => {
                      return (
                        <DropdownMenu.Item
                          key={item.label}
                          asChild
                        >
                          <Link
                            href={item.href}
                            weight='regular'
                            target='_blank'
                            color='gray'
                            highContrast
                            underline='none'
                          >
                            <DropdownMenuItemContentWithIcon
                              iconName={item.iconName}
                              label={item.label}
                            />
                          </Link>
                        </DropdownMenu.Item>
                      );
                    })}

                    <DropdownMenu.Separator />
                    <DropdownMenu.Item onClick={switchTheme}>
                      <DropdownMenuItemContentWithIcon
                        iconName={currentTheme === 'dark' ? 'sun' : 'moon-star'}
                        label={currentTheme === 'dark' ? 'Light mode' : 'Dark mode'}
                      />
                    </DropdownMenu.Item>
                    <DropdownMenu.Separator />

                    <DropdownMenu.Item asChild>
                      <NavLink to='/logout'>
                        <DropdownMenuItemContentWithIcon
                          iconName='log-out-01'
                          label='Log out'
                        />
                      </NavLink>
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </Box>
              </DropdownMenu.Root>
            </Flex>
          </Flex>
        </Grid>
      </Flex>
    </>
  );
}

Top.propTypes = {
  clipboard: PropTypes.obj,
  clerk: { openUserProfile: PropTypes.func, getToken: PropTypes.func },
  theme: { currentTheme: PropTypes.string, switchTheme: PropTypes.func },
  userInfo: {
    email: PropTypes.string,
    isOwner: PropTypes.bool,
    isDemiGod: PropTypes.bool,
    isGod: PropTypes.bool,
    assumeOrganizationId: PropTypes.number,
    assumedOrganizationId: PropTypes.func,
    toggleIsViewingAsAssumedOrganization: PropTypes.func,
    usersOrganizations: PropTypes.array,
    usersCurrentOrganizationId: PropTypes.number,
    changeOrganization: PropTypes.func,
  },
  status: PropTypes.obj,
  organizations: PropTypes.obj,
};

export default Top;
