import React, { useEffect, useState } from 'react';
import SiteContainer from '~/components/SiteContainer';
import Loader from '~/components/Loader';

function SiteLoading() {
  const [render, setRender] = useState(false);

  useEffect(() => {
    // Set to 200ms because that's what it defaults to in react-loadable
    // https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
    const timer = setTimeout(() => setRender(true), 200);
    return () => clearTimeout(timer);
  }, []);

  if (!render) return null;

  return (
    <SiteContainer>
      <Loader />
    </SiteContainer>
  );
}

export default SiteLoading;
