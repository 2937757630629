import { useEffect, useState } from 'react';

export const MAC = 'MacOS';
export const WINDOWS = 'Windows';
export const LINUX = 'Linux';
export const OTHER = 'Other';

const useOS = () => {
  const [os, setOS] = useState('Unknown');

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes('mac')) {
      setOS('MacOS');
    } else if (userAgent.includes('win')) {
      setOS('Windows');
    } else if (userAgent.includes('linux')) {
      setOS('Linux');
    } else {
      setOS('Other');
    }
  }, []);

  return {
    os,
    isMac: os === MAC,
    isWindows: os === WINDOWS,
    isLinux: os === LINUX,
    isOther: os === OTHER,
  };
};

export default useOS;
