import axios from 'axios';
import { useEffect, useState } from 'react';

function useStatuspage() {
  const [overallStatus, setOverallStatus] = useState('operational');
  const [networkStatus, setNetworkStatus] = useState('operational');
  const [webhooksStatus, setWebhooksStatus] = useState('operational');
  const [apiStatus, setApiStatus] = useState('operational');
  const [appStatus, setAppStatus] = useState('operational');
  const isDevelopment = process.env.NODE_ENV === 'development';

  useEffect(() => {
    if (isDevelopment) return;
    let cancelFetcher;

    startFetcher(4000);

    return () => clearTimeout(cancelFetcher);

    async function startFetcher(waitTime) {
      cancelFetcher = setTimeout(async () => {
        const {
          data: { components },
        } = await axios.get('https://status.onomondo.com/api/v2/components.json');
        const networkStatus = components.find(({ name }) => name === 'Network')?.status;
        const webhooksStatus = components.find(({ name }) => name === 'Webhooks')?.status;
        const apiStatus = components.find(({ name }) => name === 'API')?.status;
        const appStatus = components.find(({ name }) => name === 'App')?.status;
        const hasMajorOutage = [networkStatus, webhooksStatus, apiStatus, appStatus].includes('major_outage');
        const hasPartialOutage = [networkStatus, webhooksStatus, apiStatus, appStatus].includes('partial_outage');
        const hasDegradedPerformance = [networkStatus, webhooksStatus, apiStatus, appStatus].includes(
          'degraded_performance',
        );
        const hasMaintenance = [networkStatus, webhooksStatus, apiStatus, appStatus].includes('under_maintenance');
        const overallStatus = hasMajorOutage
          ? 'major_outage'
          : hasPartialOutage
          ? 'partial_outage'
          : hasDegradedPerformance
          ? 'degraded_performance'
          : hasMaintenance
          ? 'under_maintenance'
          : 'operational';
        setOverallStatus(overallStatus);
        setNetworkStatus(networkStatus);
        setWebhooksStatus(webhooksStatus);
        setApiStatus(apiStatus);
        setAppStatus(appStatus);

        startFetcher(3 * 60 * 1000); // Calls statuspage api every three minutes
      }, waitTime || 0);
    }
  }, [isDevelopment]);

  return {
    overall: overallStatus,
    network: networkStatus,
    webhooks: webhooksStatus,
    api: apiStatus,
    app: appStatus,
  };
}

export default useStatuspage;
