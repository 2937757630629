import React from 'react';
import PropTypes from 'prop-types';
import { Flex, TabNav, Text } from '@radix-ui/themes';
import { NavLink } from 'react-router-dom';
import Icon from '~/components/Icon';

const PAGES = [
  { label: 'Home', to: '/dashboard', iconName: 'home', activeIconName: 'home-solid' },
  { label: 'SIMs', to: '/sims', iconName: 'simcard', activeIconName: 'sim-card-solid' },
  { label: 'Usage', to: '/usage', iconName: 'bar-chart-10', activeIconName: 'bar-chart-10-solid' },
  { label: 'Network lists', to: '/network-lists', iconName: 'list' },
  { label: 'Users', to: '/users', iconName: 'user', activeIconName: 'user-03-solid', isOrgMemberRestricted: true },
  { label: 'Tags', to: '/tags', iconName: 'tag-02', activeIconName: 'tag-02-solid', isOrgMemberRestricted: true },
  { label: 'Error log', to: '/error-log', iconName: 'brackets-x', isOrgMemberRestricted: true },
  {
    label: 'Webhooks',
    to: '/webhooks',
    iconName: 'webhooks',
    isOrgMemberRestricted: true,
    featureName: 'management.webhooks',
  },
  {
    label: 'API keys',
    to: '/api-keys',
    iconName: 'key-01',
    activeIconName: 'key-01-solid',
    featureName: 'management.apiKeys',
  },
  {
    label: 'Connectors',
    to: '/connectors',
    iconName: 'link-02',
    featureName: 'connectors',
  },
  {
    label: 'Audit log',
    to: '/audit-logs',
    iconName: 'fingerprint-04',
    featureName: 'management.auditLogs',
    isForOwnerGodOnly: true,
  },
];

function Menu({ location, userInfo: { isOrgMember, isOwner, isGod } }) {
  return (
    <TabNav.Root
      color='gray'
      justify='start'
    >
      <Flex
        width='100%'
        justify='center'
      >
        {PAGES.map(item => {
          const isActive = location.pathname.includes(item.to);
          if (item.isOrgMemberRestricted && isOrgMember) return null;
          if (item.isForOwnerGodOnly && !(isOwner || isGod)) return null;
          return (
            <TabNav.Link
              asChild
              key={item.label}
              active={isActive}
            >
              <NavLink to={item.to}>
                <Flex gap='2'>
                  <Icon
                    name={(isActive && item.activeIconName) || item.iconName}
                    width={20}
                    height={20}
                    color='currentColor'
                  />
                  <Text
                    size='2'
                    weight='medium'
                  >
                    {item.label}
                  </Text>
                </Flex>
              </NavLink>
            </TabNav.Link>
          );
        })}
      </Flex>
    </TabNav.Root>
  );
}

Menu.propTypes = {
  location: PropTypes.obj,
  userInfo: {
    isOrgMember: PropTypes.bool,
    isOwner: PropTypes.bool,
    isGod: PropTypes.bool,
  },
};

export default Menu;
