import React from 'react';
import SiteContainer from '~/components/SiteContainer';
import Input from '~/components/Input';
import useNetworkService from '~/hooks/use-network-service';
import Button from '~/components/Button';

function NetworkService() {
  const { file, loading, setFile, uploadFile } = useNetworkService();

  return (
    <SiteContainer>
      <h1>NetworkService</h1>
      <p>Drag and drop a new network list</p>
      <p>
        <strong>NOTE:</strong> All old networks will be removed
      </p>
      <Input
        type='drag-and-drop-file-upload'
        allowedMimeTypes={['text/csv']}
        allowedExtensions={['.csv']}
        onFile={file => {
          setFile(file);
        }}
      />
      <p>File: {file ? file.name : 'None yet'}</p>
      <Button
        onClick={uploadFile}
        disabled={!file}
        variant='primary'
      >
        {loading ? 'Uploading...' : 'Upload'}
      </Button>
    </SiteContainer>
  );
}

export default NetworkService;
