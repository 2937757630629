import styled, { keyframes } from 'styled-components';
import { Box, Callout, Card, Flex } from '@radix-ui/themes';
import { ERROR_COLOR, SUCCESS_COLOR, INFO_COLOR } from '~/lib/theme/themes';
import PropTypes from 'prop-types';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled(Card)`
  animation: ${fadeIn} 0.1s linear;
  &:hover {
    cursor: pointer;
  }
`;

const TYPE_TO_COLOR_MAPPING = {
  error: ERROR_COLOR,
  success: SUCCESS_COLOR,
  normal: INFO_COLOR,
};

function Notification({ children, icon, onClick, type }) {
  return (
    <Wrapper
      p='0'
      mb='-6'
      asChild
    >
      <Box>
        <Flex
          display='flex'
          asChild
        >
          <Callout.Root
            size='1'
            color={TYPE_TO_COLOR_MAPPING[type ?? 'normal']}
            role={type === 'error' ? 'alert' : null}
            onClick={onClick}
          >
            {icon && <Callout.Icon>{icon}</Callout.Icon>}
            <Box
              width='100%'
              asChild
            >
              <Callout.Text as='div'>{children}</Callout.Text>
            </Box>
          </Callout.Root>
        </Flex>
      </Box>
    </Wrapper>
  );
}

Notification.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['error', 'success', 'normal']),
};

export default Notification;
