import PropTypes from 'prop-types';
import ComboboxComponent, { ComboboxComponentPropTypes, ItemPropType } from './main';
import { Flex, Text } from '@radix-ui/themes';
import { InputErrorText, InputHelpText } from '../Text';

function ComboBox({ helpText, label, errorText, ...rest }) {
  const labelEl = !!label && (
    <Text
      ml='5px'
      size='1'
      as='label'
    >
      {label}
    </Text>
  );

  const comboBoxEl = <ComboboxComponent {...rest} />;

  const helpTextEl =
    !!helpText && typeof helpText === 'function' ? (
      helpText()
    ) : (
      <InputHelpText
        ml='5px'
        size='1'
      >
        {helpText}
      </InputHelpText>
    );

  const errorTextEl = !!errorText && (
    <InputErrorText
      ml='5px'
      size='1'
    >
      {errorText}
    </InputErrorText>
  );

  return (
    <Flex direction='column'>
      {labelEl}
      {comboBoxEl}
      {errorTextEl || helpTextEl}
    </Flex>
  );
}

ComboBox.propTypes = {
  ComboboxComponentPropTypes,
  helpText: PropTypes.string,
  label: PropTypes.string,
  errorText: PropTypes.string,
};

export default ComboBox;
export { ComboboxComponent, ItemPropType };
