import React from 'react';
import SiteContainer from '~/components/SiteContainer';
import Menu from '../Menu';
import CountriesService from '~/containers/CountryService';

function CountriesServicePage() {
  return (
    <SiteContainer>
      <Menu />
      <CountriesService />
    </SiteContainer>
  );
}

export default CountriesServicePage;
