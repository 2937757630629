import React from 'react';
import styled from 'styled-components';
import useNotification from '~/hooks/use-notification';
import { NavLink } from 'react-router-dom';
import Notification from '~/components/Notification';
import Icon from '~/components/Icon';
import mixpanel from 'mixpanel-browser';
import { Button, Flex } from '@radix-ui/themes';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => props.theme.zIndex.notification};
  width: 100%;
  margin-top: 14px;
  padding: 0 60px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

function NotificationCenter() {
  const { notifications, cancelNotification } = useNotification();

  return (
    <Wrapper>
      <List>
        {notifications.map((notification, idx) => (
          <Notification
            key={idx}
            type={notification.type}
            onClick={() => cancelNotification(notification.id)}
            icon={
              notification.icon && (
                <Icon
                  inline
                  {...notification.icon}
                />
              )
            }
          >
            <Flex
              justify='between'
              width='100%'
            >
              <div>{notification.message}</div>
              <div>
                {notification.mode === 'timed' ? (
                  <Icon
                    name='close'
                    width={15}
                    height={15}
                    color='currentColor'
                  />
                ) : (
                  <Button
                    size='1'
                    variant='outline'
                    onClick={() => mixpanel.track('Click on notification button', notification)}
                  >
                    <NavLink to={notification.buttonHref}>{notification.buttonLabel || 'See more'}</NavLink>
                  </Button>
                )}
              </div>
            </Flex>
          </Notification>
        ))}
      </List>
    </Wrapper>
  );
}

export default NotificationCenter;
